<template>
  <zg-section
    width="xl"
    class="hero-cta"
    no-y-padding
    style="background: transparent;"
  >
    <div
      :class="['box', {
        'box--right': NORWAY,
        'box--min-height-no': NORWAY,
        'box--min-height-fi': FINLAND
      }]"
    >
      <h2 v-html="highlightTitle({ market: MARKET, text: title })" />
      <p>{{ subTitle }}</p>

      <lazy-hydrate never>
        <fragment
          v-if="useCalculatorFragment"
          name="consumerLoanCalculator"
          :custom-query="fragmentCustomQuery"
          :pre-render="!LOCAL"
        />

        <fragment
          v-else
          name="consumerLoanForm"
          :custom-query="fragmentCustomQuery"
          :pre-render="!LOCAL"
        />
      </lazy-hydrate>
    </div>
  </zg-section>
</template>

<script>
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapGetters } from 'vuex'
  import { highlightTitle } from '../../../../utils'
  import Fragment from '../../../elements/misc/Fragment'

  export default {
    name: 'BrokerHeroCta',
    components: {
      LazyHydrate,
      Fragment,
      ZgSection: () => import(/* webpackChunkName: 'zc/zg-section' */
        '@zc/components/ZgSection/ZgSection.vue')
    },
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      subTitle: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      ...mapGetters('translations', ['getLocale']),
      useCalculatorFragment () {
        return !this.FINLAND
      },
      fragmentCustomQuery () {
        return {
          disclaimer: true,
          locale: this.getLocale,
          op_user_id: this.getRoute?.userId,
          ...(this.TESTCAFE ? { testcafe: true } : {})
        }
      }
    },
    methods: {
      highlightTitle
    }
  }
</script>

<style lang="scss" scoped>
  .hero-cta {
    padding: 0;
    background-color: transparent !important; /* stylelint-disable-line */

    @include mq(medium) {
      padding: rhythm(10) 0 !important; /* stylelint-disable-line */
    }
  }

  .box {
    background-color: #fff;
    padding: rhythm(small);
    margin: 0 (- rhythm(small));

    @include mq(medium) {
      border-radius: radius(box);
      box-shadow: box-shadow();
      padding: rhythm();
      margin: 0;
    }

    @include mq(large) {
      width: rhythm(76);
    }

    &--right {
      @include mq(medium) {
        margin-left: auto;
      }
    }

    &--min-height-no {
      @include mq(medium) {
        min-height: rem(614px);
      }
    }

    &--min-height-fi {
      @include mq(medium) {
        min-height: rem(672px);
      }
    }
  }

  h2 {
    :deep(span) {
      color: #da8b2d;
    }
  }
</style>
