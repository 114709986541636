var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zg-section",
    {
      staticClass: "hero-cta",
      staticStyle: { background: "transparent" },
      attrs: { width: "xl", "no-y-padding": "" },
    },
    [
      _c(
        "div",
        {
          class: [
            "box",
            {
              "box--right": _vm.NORWAY,
              "box--min-height-no": _vm.NORWAY,
              "box--min-height-fi": _vm.FINLAND,
            },
          ],
        },
        [
          _c("h2", {
            domProps: {
              innerHTML: _vm._s(
                _vm.highlightTitle({ market: _vm.MARKET, text: _vm.title })
              ),
            },
          }),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
          _vm._v(" "),
          _c(
            "lazy-hydrate",
            { attrs: { never: "" } },
            [
              _vm.useCalculatorFragment
                ? _c("fragment", {
                    attrs: {
                      name: "consumerLoanCalculator",
                      "custom-query": _vm.fragmentCustomQuery,
                      "pre-render": !_vm.LOCAL,
                    },
                  })
                : _c("fragment", {
                    attrs: {
                      name: "consumerLoanForm",
                      "custom-query": _vm.fragmentCustomQuery,
                      "pre-render": !_vm.LOCAL,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }