/**
 * @param {string} market
 * @param {string} text
 * @param {string|null} highlight
 * @returns {*}
 */
export function highlightTitle ({ market, text, highlight = null } = {}) {
  if (!market || !text) return

  let title = text
  const highlights = {
    se: [
      'Freedom Finance',
      'Jämför & ansök',
      'Privatlån',
      'Samla lån',
      'Billån'
    ],
    no: [
      '1-2-3',
      'hjelper'
    ],
    fi: [
      'lainojen',
      'laina',
      'lainan kilpailuttaminen'
    ]
  }[market]

  if (highlight) highlights.push(highlight)

  highlights && highlights
    .forEach(item => {
      const regex = new RegExp(`(${item})`, 'g')
      title = title?.replace(regex, '<span class="title-highlight">$1</span>')
    })

  return title
}
