var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "fragment",
        {
          [`fragment--background-${_vm.background}`]: _vm.background,
        },
      ],
      style: { "min-height": `${_vm.minHeight}px` },
    },
    [
      _vm.fragmentUrl
        ? _c("div", { attrs: { "data-fragment-url": _vm.fragmentUrl || "" } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }